function NoOnepagerNotice() {
  return (
    <div className="flex flex-col justify-center container max-w-lg mx-auto min-h-screen">
      <div className="bg-white rounded shadow p-10 text-center">
        <h3 className="font-bold text-3xl appTitleItem mb-3">
          No Onepager Found
        </h3>
        <p className="">
          If you are the author of this onepager. Make sure to double check the
          URL to make sure it is correct.
        </p>
        <div className="my-10">-------------------------</div>
        <h4 className="font-bold text-2xl">Create Your Own Onepager!</h4>
        <a
          href="https://onepager.io/"
          className="inline-block bg-black text-white rounded py-3 px-4 font-bold text-xl mt-5"
        >
          Sign Up For Free
        </a>
      </div>
      <div className="mt-10 mb-10 flex items-center justify-center">
        <a
          href="https://onepager.io/"
          className="font-bold mr-3 flex items-center hover:opacity-75"
        >
          <img
            className="h-6 w-auto mr-3"
            src="https://cdn.filestackcontent.com/jW9hY5L5RAWWpn2rGhw9"
            alt="onepager"
          />
          onepager.io
        </a>
      </div>
    </div>
  )
}

export default NoOnepagerNotice
