import faunadb from 'faunadb'
import { useCookie } from 'next-cookie'
import _ from 'lodash'
import Cookies from 'js-cookie'
import { RecoilRoot } from 'recoil'
import { opTitle, opDesc } from '@/utils/store/onepager_template'
import { layoutIds, layout } from '@/store/template/layout'
import { sectionIds, section } from '@/store/template/section'
import { contentIds, content, content_block } from '@/store/template/content'
import { settings, grid_matrix, explainer } from '@/store/template/settings'
import { design } from '@/store/template/design'
import { isBuilder } from '@/store/builder/store'
import { publicIsLoggedIn, currentTemplateID } from '@/store/onepager/onepager'
import dynamic from 'next/dynamic'
import Classnames from 'classnames'
import { useState } from 'react'

const PublicOnepager = dynamic(() => import('@/components/onepager/Public'), {
  ssr: false,
})
const Cover = dynamic(() => import('@/components/onepager/CoverImage'), {
  ssr: false,
})
const TwoColumns = dynamic(() => import('@/components/onepager/TwoColumns'), {
  ssr: false,
})
const SavedHistory = dynamic(
  () => import('@/components/onepager/SavedHistory'),
  {
    ssr: false,
  }
)
import PublicMeta from '@/components/onepager/PublicMeta'
import NoOnepagerNotice from '@/components/app/NoOnepagerNoticeError'

export default function OnepagerURL({
  isTemplate,
  isCollection,
  slug,
  template,
  collection,
  isPreview,
  printPDF,
  onepagerId,
}) {
  const [reloadSaveHistory, setReloadSaveHistory] = useState(0)
  const [loggedInEmail, setLoggedInEmail] = useState(null)
  // init values for recoil
  function initState({ set }) {
    set(isBuilder, false)

    // check if cookie wl-email (signed up with email)
    // check if cookie auth=true
    if (Cookies.get('wl-email')) {
      set(publicIsLoggedIn, true)
      setLoggedInEmail(Cookies.get('wl-email'))
    }
    set(currentTemplateID, template._id)

    set(opTitle, template.title)
    set(opDesc, template.description)

    set(grid_matrix, template.grid_matrix)

    // settings & design
    if (template?.settings) {
      try {
        set(settings, JSON.parse(template.settings))
      } catch (e) {
        set(settings, template.settings)
      }
    }
    if (template?.design) {
      try {
        set(design, JSON.parse(template.design))
      } catch (e) {
        set(design, template.design)
      }
    }

    if (template?.explainer) {
      try {
        set(explainer, JSON.parse(template.explainer))
      } catch (e) {
        set(explainer, template.explainer)
      }
    }

    // build layouts
    let allLayoutIds = []

    if (template?.layout) {
      try {
        _.forEach(JSON.parse(template.layout), function (layoutData) {
          allLayoutIds.push(layoutData.id)
          set(layout(layoutData.id), layoutData.atom)
        })
      } catch (e) {
        _.forEach(template.layout, function (layoutData) {
          allLayoutIds.push(layoutData.id)
          set(layout(layoutData.id), layoutData.atom)
        })
      }
    }

    set(layoutIds, allLayoutIds)

    // build sections
    let allSectionIds = []

    if (template?.sections) {
      try {
        _.forEach(JSON.parse(template.sections), function (sectionData) {
          allSectionIds.push(sectionData.id)
          set(section(sectionData.id), sectionData.atom)
        })
      } catch (e) {
        _.forEach(template.sections, function (sectionData) {
          allSectionIds.push(sectionData.id)
          set(section(sectionData.id), sectionData.atom)
        })
      }
    }

    set(sectionIds, allSectionIds)

    // LOADING FROM TEMPLATE

    // build contents
    let allContentIds = []

    if (template.content) {
      try {
        _.forEach(JSON.parse(template.content), function (contentData) {
          allContentIds.push(contentData.id)
          set(content(contentData.id), contentData.atom)
        })
      } catch (e) {
        _.forEach(template.content, function (contentData) {
          allContentIds.push(contentData.id)
          set(content(contentData.id), contentData.atom)
        })
      }
    }

    set(contentIds, allContentIds)

    // build content_blocks
    if (template?.content_blocks) {
      try {
        _.forEach(
          JSON.parse(template.content_blocks),
          function (contentBlockData) {
            set(content_block(contentBlockData.id), contentBlockData.atom)
          }
        )
      } catch (e) {
        _.forEach(template.content_blocks, function (contentBlockData) {
          set(content_block(contentBlockData.id), contentBlockData.atom)
        })
      }
    }
  }

  if (!template) {
    return (
      <div className="bg-gray-50 dottedBG min-h-screen">
        <NoOnepagerNotice />
      </div>
    )
  } else {
    return (
      <RecoilRoot initializeState={initState}>
        <div
          className={Classnames('', {
            isPrintPDF: printPDF,
          })}
        >
          {/* Meta info */}
          <PublicMeta
            template={template}
            collection={collection}
            isTemplate={isTemplate}
            isCollection={false}
            slug={slug}
          />

          <div>
            {/* DEFAULT - if layout_option is false */}
            {!template.layout_option && (
              <div>
                <PublicOnepager
                  template={template}
                  collection={collection}
                  isTemplate={isTemplate}
                  isCollection={isCollection}
                  hasSignedUp={() => {
                    setLoggedInEmail(Cookies.get('wl-email'))
                  }}
                  reloadSaveHistory={() => {
                    setReloadSaveHistory(reloadSaveHistory + 1)
                  }}
                />
              </div>
            )}

            {/*  HAS LAYOUT_OPTION */}
            {/* DEFAULT */}
            {template.layout_option == 'vert' && (
              <div>
                <PublicOnepager
                  template={template}
                  collection={collection}
                  isTemplate={isTemplate}
                  isCollection={isCollection}
                  hasSignedUp={() => {
                    setLoggedInEmail(Cookies.get('wl-email'))
                  }}
                  reloadSaveHistory={() => {
                    setReloadSaveHistory(reloadSaveHistory + 1)
                  }}
                />
              </div>
            )}

            {/* TWO COLUMNS */}
            {template.layout_option == 'twocolumn' && (
              <TwoColumns
                template={template}
                collection={collection}
                isTemplate={isTemplate}
                isCollection={isCollection}
                reloadSaveHistory={() => {
                  setReloadSaveHistory(reloadSaveHistory + 1)
                }}
              />
            )}

            {/* WIZARD */}
            {template.layout_option == 'wizard' && (
              <Cover
                template={template}
                collection={collection}
                isTemplate={isTemplate}
                isCollection={isCollection}
                reloadSaveHistory={() => {
                  setReloadSaveHistory(reloadSaveHistory + 1)
                }}
              />
            )}
          </div>

          {loggedInEmail && (
            <div style={{ zIndex: 999999999, position: 'relative' }}>
              <SavedHistory
                email={loggedInEmail}
                onepagerId={onepagerId}
                reloadSaveHistory={reloadSaveHistory}
              />
            </div>
          )}
        </div>
      </RecoilRoot>
    )
  }
}

// export async function getStaticPaths() {
//   return { paths: [], fallback: 'blocking' }
// }

export async function getServerSideProps(context) {
  let reqHost = context.req.headers.host
  reqHost = reqHost.split('.')
  const nxtCookie = useCookie(context)

  let getURL = context.params.url
  getURL = getURL.split('-')
  getURL = getURL.pop()

  // local testing - add .com
  if (reqHost[1] == 'localhost:8181') {
    reqHost.push('.com')
  }

  // check if autosignup is set
  if (context.query.autosignup) {
    // create cookie!
    let email = decodeURIComponent(context.query.autosignup)
    email = email.replace(/\s+/g, '+')
    email = email.toLowerCase()
    nxtCookie.set('wl-email', email, {
      expires: 14,
    })
  }

  let printPDF = false
  if (context.query.printpdf) {
    printPDF = true
  }

  const q = faunadb.query
  const client = new faunadb.Client({
    timeout: 3000,
    secret: process.env.FAUNADB_SERVER_KEY,
  })

  // check if this has a subdomain and is ID is numeric string (the id)
  if (reqHost.length == 3 && reqHost[0] !== 'app' && parseInt(getURL)) {
    // check if URL last item is a ref
    const checkIfRef = await client.query(
      q.IsRef(q.Ref(q.Collection('Template'), `${getURL}`))
    )

    if (checkIfRef) {
      let getOnepager = await client
        .query(q.Get(q.Ref(q.Collection('Template'), `${getURL}`)))
        .then(async function (ret) {
          let getTemplate = ret.data
          getTemplate._id = ret.ref.id
          // getTemplate.collection = null
          getTemplate.user = null

          // get collection info (logo, branding, etc)
          let getCollection = {}

          if (ret.data.collection) {
            getCollection = await client
              .query(q.Get(ret.data.collection))
              .then(async collectionReturn => {
                let collectionData = collectionReturn.data
                collectionData._id = collectionReturn.ref.id

                let getSubdomain = await client
                  .query(q.Get(collectionReturn.data.subdomain))
                  .then(async subdomainReturn => {
                    return subdomainReturn.data.slug
                  })

                collectionData.subdomain = getSubdomain

                return collectionData
              })
              .catch(err => false)
          }

          // get integration
          let getIntegration = {}
          if (ret.data.integration) {
            getIntegration = await client
              .query(q.Get(ret.data.integration))
              .then(async integrationReturn => {
                return {
                  _id: integrationReturn.ref.id,
                  service: integrationReturn.data.service,
                }
              })
              .catch(err => false)
          }

          getTemplate.collection = getCollection
          getTemplate.integration = getIntegration

          if (getTemplate.collection) {
            getTemplate.collection.user = null
          }

          if (getTemplate?.subdomain) {
            let getSubdomain = await client
              .query(q.Get(getTemplate?.subdomain))
              .then(async subdomainReturn => {
                return subdomainReturn.data.slug
              })

            getTemplate.subdomain = getSubdomain
          }

          return {
            template: getTemplate,
            collection: getCollection,
          }
        })
        .catch(err => false)

      if (!getOnepager) {
        getOnepager = {
          template: null,
          collection: null,
        }
      }

      return {
        props: {
          isTemplate: true,
          isCollection: true,
          slug: reqHost[0],
          template: getOnepager?.template,
          collection: getOnepager?.collection,
          isPreview: false,
          printPDF: printPDF,
          onepagerId: getURL,
        },
      }
    } else {
      // redirect ...
      return {
        redirect: {
          permanent: false,
          destination: '/',
        },
      }
    }
  } else {
    return {
      redirect: {
        permanent: false,
        destination: '/',
      },
    }
  }
}
